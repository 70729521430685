import request from '@/utils/request'
export function registeruser(data) {
  return request({
    url: '/horizou/index/ssq_create_user',
    method: 'post',
    data: data
  })
}
export function CheckTelCode(data) {
  return request({
    url: '/horizou/index/ssq_check_tel_code',
    method: 'post',
    data: data
  })
}
